$(document).ready(function() {

  var bLazy = new Blazy({
      selector: 'img,.b-lazy-background'
  });
                
  $('#nav-button').on('click', function() {
      $('.navigation').toggleClass('active');
      $('.header').toggleClass('nav-fixed');
      $('.wrapper').toggleClass('nav-fixed');
  });
  $('.services_button').on('click', function(e) {
      e.preventDefault();
      $('.features_list').toggleClass('is-visible');
  });
  $(document).scroll(function() {
    var scroll = $(this).scrollTop();
    if (scroll >= 1) {
      $(".header").addClass("menu-fixed");
    } else{
      $(".header").removeClass("menu-fixed");
    }
  });
  
  // Hide Header on on scroll down
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $('header').outerHeight();

  $(window).scroll(function(event){
      didScroll = true;
  });

  setInterval(function() {
      if (didScroll) {
          hasScrolled();
          didScroll = false;
      }
  }, 250);

  function hasScrolled() {
      var st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if(Math.abs(lastScrollTop - st) <= delta)
          return;

          // If they scrolled down and are past the navbar, add class .nav-up.
          // This is necessary so you never see what is "behind" the navbar.
          if (st > lastScrollTop && st > navbarHeight){
              // Scroll Down
              $('header').removeClass('nav-down').addClass('nav-up');
          } else {
              // Scroll Up
              if(st + $(window).height() < $(document).height()) {
                  $('header').removeClass('nav-up').addClass('nav-down');
              }
          }

          lastScrollTop = st;
      }
});
if ($(".fancybox").length){
    $(document).ready(function() {
        $(".fancybox").fancybox({
            openEffect  : 'none',
            closeEffect : 'none'
        });
    });
}

if ($("#map").length){
  google.maps.event.addDomListener(window, 'load', init);
  function init() {
      // Basic options for a simple Google Map
      // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
      var mapOptions = {
          // How zoomed in you want the map to start at (always required)
          zoom: 9,
          // The latitude and longitude to center the map (always required)
          center: new google.maps.LatLng(-1.953580, 42.776280), // Iturgoien
          // How you would like to style the map. 
          // This is where you would paste any style found on Snazzy Maps.
          styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#e66e3b"},{"visibility":"on"}]}]
      };
      // Get the HTML DOM element that will contain your map 
      // We are using a div with id="map" seen below in the <body>
      var mapElement = document.getElementById('map');
      // Create the Google Map using our element and options defined above
      var map = new google.maps.Map(mapElement, mapOptions);
      // Let's also add a marker while we're at it
      var marker = new google.maps.Marker({
          position: new google.maps.LatLng(-1.953580, 42.776280),
          map: map,
          title: 'Casa Rural Argiñe Etxea, C/San Antón 7 Iturgoien (Nafarroa)'
      });
  }
}